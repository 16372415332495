import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import { navigate } from "gatsby"
import aappointPhone from '../images/footer/aappointPhone.png'
import qrCode from '../images/footer/qrCode.png'
import facebook from '../images/footer/facebook.png'
import instagram from '../images/footer/instagram.png'
import youtube from '../images/footer/youtube.png'
import copyright from '../images/footer/copyright.png'
import Divider from '@material-ui/core/Divider'


export class Footer extends Component {
    render() {
        return (
            <div>
                <MediaQuery minWidth={1024}>
                    <div lang={`${this.props.lang}`}>
                        {/* Download now section */}
                        <div className="h-20"></div>
                        <div className="container-1420 mx-auto grid grid-cols-12 bg-content-container items-center">
                            <div className="col-span-3"></div>
                            <div className="col-span-2 my-5">
                                <img className="ml-auto" alt="aappointPhone" src={aappointPhone} width="77.7%" />
                            </div>
                            <div></div>
                            <div className="col-span-4 items-center">
                                <div className="
                                text-body-header font-bold my-2
                                2xl:text-body-head-36-size
                                xl:text-body-head-36-size
                                lg:text-body-head-30-size
                            ">
                                    {this.props.locale[`${this.props.lang}`].DOWNLOAD_NOW_L1}
                                </div >
                                <div className="
                                text-body-header font-bold mb-5
                                2xl:text-body-head-24-size
                                xl:text-body-head-24-size
                                lg:text-body-head-20-size
                            ">
                                    {this.props.locale[`${this.props.lang}`].DOWNLOAD_NOW_L2}
                                </div >
                                <img alt="qrCode" src={qrCode} width="76.6%" />
                            </div>
                            <div className="col-span-2"></div>
                        </div>
                        <div className="h-12"></div>
                        <Divider />
                        {/* Site map section */}
                        <div className="h-10"></div>
                        <div className="container-1420 mx-auto grid grid-cols-12">
                            <div className="col-span-2 text-body-content-gray text-body-con-16-size font-medium mx-auto">
                                <div
                                    className="font-bold"
                                >
                                    {this.props.locale[`${this.props.lang}`].PRODUCT}
                                </div>
                                <div className="h-4"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].FEATURE}
                                </div>
                            </div>
                            <div className="col-span-2 text-body-content-gray text-body-con-16-size font-medium ml-4vw">
                                <div
                                    onClick={() => navigate('/')}
                                    className="font-bold cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].SOLUTION}
                                </div>
                                <div className="h-4"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].AESTHETIC}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].MEDICAL}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].DENTAL}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].MASSAGE}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].GARAGE}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].CARWASH}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].PET}
                                </div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover whitespace-nowrap"
                                >
                                    {this.props.locale[`${this.props.lang}`].BEAUTY}
                                </div>
                            </div>
                            <div className="col-span-2 text-body-content-gray text-body-con-16-size font-medium text-center">
                                <div
                                    className="font-bold"
                                >
                                    {this.props.locale[`${this.props.lang}`].PRICING}
                                </div>
                                <div className="h-4"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].PRICING}
                                </div>
                            </div>
                            <div className="col-span-2 text-body-content-gray text-body-con-16-size font-medium ml-20p">
                                <div
                                    onClick={() => navigate('/')}
                                    className="font-bold cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].ABOUT_US}
                                </div>
                                <div className="h-4"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className="cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].CONTACT_US}
                                </div>
                                <div
                                    onClick={() => { 
                                        if (this.props.lang === 'EN') {
                                            navigate('th/privacy')
                                        } else {
                                            navigate('th/privacy')
                                        }}
                                    }
                                    className="cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].PRIVACY}
                                </div>
                                <div
                                    onClick={() => { 
                                        if (this.props.lang === 'EN') {
                                            navigate('th/term-of-use')
                                        } else {
                                            navigate('th/term-of-use')
                                        }}
                                    }
                                    className="cursor-pointer hover:text-menu-hover"
                                >
                                    {this.props.locale[`${this.props.lang}`].TERM}
                                </div>
                            </div>
                            <div className="col-span-2 text-body-content-gray text-body-con-16-size font-medium text-center">
                                <div className="font-bold">{this.props.locale[`${this.props.lang}`].JOIN_US}</div>
                                <div className="h-4"></div>
                                <div className="grid grid-cols-12 items-center">
                                    <div className="col-span-2"></div>
                                    <img
                                        className="col-span-2 ml-auto cursor-pointer w-6"
                                        alt="facebook"
                                        onClick={() => window.open('https://www.facebook.com/AAppoint.me')}
                                        src={facebook}
                                    />
                                    <div className="col-span-1"></div>
                                    <img
                                        className="col-span-2 mx-auto cursor-pointer w-7"
                                        alt="instagram"
                                        onClick={() => window.open('https://www.instagram.com/aappoint_official')}
                                        src={instagram}
                                    />
                                    <div className="col-span-1"></div>
                                    <img
                                        className="col-span-2 mr-auto cursor-pointer w-9"
                                        alt="youtube"
                                        onClick={() => window.open('https://www.youtube.com/channel/UCIbSLJ7qHs71QEIJkjwRiEA')}
                                        src={youtube}
                                    />
                                    <div className="col-span-2"></div>
                                </div>
                            </div>
                            <div className="col-span-2 text-body-content-gray text-body-con-16-size font-medium text-center">
                                <div
                                    onClick={() => navigate('/faqs')}
                                    className="cursor-pointer hover:text-menu-hover font-bold
                                        ">
                                    {this.props.locale[`${this.props.lang}`].FAQ}
                                </div>
                            </div>
                        </div>
                    </div >
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <div className="h-12 md:h-16 sm:h-14"></div>
                    <div className=" bg-content-container" lang={`${this.props.lang}`}>
                        <div className="container-footer mx-auto grid grid-cols-12 items-center">
                            <div className="col-span-1"></div>
                            <div className="col-span-4">
                                <img className="ml-auto my-8" alt="aappointPhone" src={aappointPhone} width="77.7%" />
                            </div>
                            <div className="col-span-1"></div>
                            <div className="col-span-6">
                                <div>
                                    <div className="
                                        text-body-header font-bold mt-2 -ml-5p
                                        text-size-18 md:text-size-24 sm:text-size-20
                                    ">
                                        {this.props.locale[`${this.props.lang}`].DOWNLOAD_NOW_L1}
                                    </div >
                                    <div className="
                                        text-body-header font-bold -ml-5p
                                        mb-3 md:mb-5 sm:mb-4
                                        text-size-10 md:text-size-15 sm:text-size-12
                                ">
                                        {this.props.locale[`${this.props.lang}`].DOWNLOAD_NOW_L2}
                                    </div >
                                    <img className="-ml-5p" alt="qrCode" src={qrCode} width="76.6%" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Site map section */}
                    <div className="h-10"></div>
                    <div className={this.props.lang === 'EN' ?
                        "container-footer mx-auto grid grid-cols-2 text-size-14 md:text-size-16 sm:text-size-16" // For EN Layout
                        :
                        "container-footer mx-auto grid grid-cols-5 text-size-14 md:text-size-16 sm:text-size-16" // For TH Layout
                    }>
                        <div className={this.props.lang === 'EN' ?
                            "col-span-1 text-body-content-gray font-medium pl-20p" // For EN Layout
                            :
                            "col-span-3 text-body-content-gray font-medium pl-3p" // For TH Layout
                        }>
                            <div
                                className="font-bold"
                            >
                                {this.props.locale[`${this.props.lang}`].PRODUCT}
                            </div>
                            <div className="h-2"></div>
                            <div
                                onClick={() => navigate('/features')}
                            >
                                {this.props.locale[`${this.props.lang}`].FEATURE}
                            </div>
                        </div>
                        <div className={this.props.lang === 'EN' ?
                            "col-span-1 text-body-content-gray font-medium"
                            :
                            "col-span-2 text-body-content-gray font-medium md:-ml-20p sm:-ml-20p"
                        }>
                            <div
                                className="font-bold"
                            >
                                {this.props.locale[`${this.props.lang}`].PRICING}
                            </div>
                            <div className={this.props.lang === 'EN' ? "h-2" : "col-span-2 h-2"}></div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].PRICING}
                            </div>
                        </div>
                        <div className={this.props.lang === 'EN' ? "col-span-2 h-9" : "col-span-5 h-9"}></div>
                        <div
                            className={this.props.lang === 'EN' ?
                                "col-span-1 text-body-content-gray font-medium pl-20p"
                                :
                                "col-span-3 text-body-content-gray font-medium pl-3p"
                            }>
                            <div
                                onClick={() => navigate('/')}
                                className="font-bold"
                            >
                                {this.props.locale[`${this.props.lang}`].SOLUTION}
                            </div>
                            <div className={this.props.lang === 'EN' ? "h-4" : "col-span-2 h-4"}></div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].AESTHETIC}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].MEDICAL}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].DENTAL}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].MASSAGE}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].GARAGE}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].CARWASH}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].PET}
                            </div>
                            <div
                                onClick={() => navigate('/')}
                            >
                                {this.props.locale[`${this.props.lang}`].BEAUTY}
                            </div>
                        </div>
                        <div
                            className={this.props.lang === 'EN' ?
                                "col-span-1 text-body-content-gray font-medium"
                                :
                                "col-span-2 text-body-content-gray font-medium md:-ml-20p sm:-ml-20p"
                            }>
                            <div
                                onClick={() => navigate('/')}
                                className="font-bold cursor-pointer"
                            >
                                {this.props.locale[`${this.props.lang}`].ABOUT_US}
                            </div>
                            <div
                                className={this.props.lang === 'EN' ?
                                    "h-4"
                                    :
                                    "col-span-2 h-4"
                                }></div>
                            <div
                                onClick={() => navigate('/')}
                                className="cursor-pointer"
                            >
                                {this.props.locale[`${this.props.lang}`].CONTACT_US}
                            </div>
                            <div
                                onClick={() => { 
                                    if (this.props.lang === 'EN') {
                                        navigate('th/privacy')
                                    } else {
                                        navigate('th/privacy')
                                    }}
                                }
                                className="cursor-pointer"
                            >
                                {this.props.locale[`${this.props.lang}`].PRIVACY}
                            </div>
                            <div
                                onClick={() => { 
                                    if (this.props.lang === 'EN') {
                                        navigate('th/term-of-use')
                                    } else {
                                        navigate('th/term-of-use')
                                    }}
                                }
                                className="cursor-pointer"
                            >
                                {this.props.locale[`${this.props.lang}`].TERM}
                            </div>
                            <div className="h-3"></div>
                            <div
                                onClick={() => navigate('/')}
                                className="font-bold cursor-pointer"
                            >
                                {this.props.locale[`${this.props.lang}`].FAQ}
                            </div>
                            <div className="h-3"></div>
                            {/* Join Us section */}
                            <div className="font-bold">{this.props.locale[`${this.props.lang}`].JOIN_US}</div>
                            <div className="h-4"></div>
                            <div className="grid grid-cols-12 items-center">
                                <img
                                    className="
                                        col-span-3 md:col-span-2 sm:col-span-2
                                        mr-auto cursor-pointer w-6
                                    "
                                    alt="facebook"
                                    onClick={() => window.open('https://www.facebook.com/AAppoint.me')}
                                    src={facebook}
                                />
                                <img
                                    className="
                                        col-span-3 md:col-span-2 sm:col-span-2
                                        mr-auto cursor-pointer w-7
                                    "
                                    alt="instagram"
                                    onClick={() => window.open('https://www.instagram.com/aappoint_official')}
                                    src={instagram}
                                />
                                <img
                                    className="
                                        col-span-3 md:col-span-2 sm:col-span-2
                                        mr-auto cursor-pointer w-9
                                    "
                                    alt="youtube"
                                    onClick={() => window.open('https://www.youtube.com/channel/UCIbSLJ7qHs71QEIJkjwRiEA')}
                                    src={youtube}
                                />
                                <div className="col-span-3 md:col-span-6 sm:col-span-6"></div>
                            </div>
                            {/* Join Us section */}
                        </div>
                        <div></div>
                    </div>
                    {/* Site map section */}
                </MediaQuery>
                {/* Copy right section */}
                <div className="h-10"></div>
                <div className="bg-copyright-footer h-20 text-body-content-gray text-body-con-16-size font-medium text-center">
                    <div className="inline-block mt-8">{this.props.locale[`${this.props.lang}`].COPYRIGHT}</div>
                    <img className="inline-block mx-1" alt="copyright" src={copyright} width="16px" />
                    <div className="inline-block mt-8">{this.props.locale[`${this.props.lang}`].AAPPOINT}</div>
                </div>
            </div>
        )
    }
}

export default Footer