import React, { Component } from 'react'
import MediaQuery from 'react-responsive'
import aappointLogo from '../images/header/AAppoint_Logo.svg'
import menu from '../images/header/menu.png'
import iconClose from '../images/header/iconClose.png'
import Divider from '@material-ui/core/Divider'
import { navigate } from "gatsby"

export class Header extends Component {
    constructor(props) {
        super(props)

        this.state = {
            expandMenu: false,
            inverseLang: '',
        }
        this.toggleMenu = this.toggleMenu.bind(this)
        this.inverseLang = this.inverseLang.bind(this)
    }

    componentDidMount() {
        this.inverseLang()
    }

    toggleMenu = () => {
        this.setState({ expandMenu: !this.state.expandMenu })
    }
    inverseLang = (currentLang) => {
        if (!currentLang) {
            if (this.props.lang === 'TH') {
                this.setState({ inverseLang: 'EN' })
            } else {
                this.setState({ inverseLang: 'TH' })
            }
        } else {
            if (currentLang === 'TH') {
                this.setState({ inverseLang: 'TH' })
            } else {
                this.setState({ inverseLang: 'EN' })
            }
        }
    }
    render() {
        return (
            // at master branch
            <div>
                <MediaQuery minWidth={1024}>
                    <div>
                        <div className="w-full bg-white z-50 fixed" lang={`${this.props.lang}`}>
                            <div className="container-header mx-auto">
                                <div className="h-4"></div>
                                <div className="grid grid-cols-12 gap-2 items-center">
                                    <img
                                        alt="aappointLogo"
                                        className="col-span-2 cursor-pointer
                                            max:pl-4vw 2xl:pl-2vw xl:pl-2vw lg:pl-2vw
                                        "
                                        onClick={() => navigate('/')}
                                        src={aappointLogo}
                                    />
                                    <div className="col-span-2"></div>
                                    <div onClick={() => navigate('/')}
                                        className="cursor-pointer  text-menu-color font-bold hover:text-menu-hover
                                            max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-14
                                    ">
                                        {this.props.locale[`${this.props.lang}`].FEATURE}
                                    </div>
                                    <div onClick={() => navigate('/')}
                                        className="cursor-pointer  text-menu-color font-bold hover:text-menu-hover
                                        max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-14
                                    ">
                                        {this.props.locale[`${this.props.lang}`].PRICING}
                                    </div>
                                    <div onClick={() => navigate('/')}
                                        className="cursor-pointer  text-menu-color font-bold hover:text-menu-hover
                                        max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-14
                                    ">
                                        {this.props.locale[`${this.props.lang}`].SOLUTION}
                                    </div>
                                    <div onClick={() => navigate('/')}
                                        className="cursor-pointer  text-menu-color font-bold hover:text-menu-hover
                                        max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-14
                                    ">
                                        {this.props.locale[`${this.props.lang}`].ABOUT_US}
                                    </div>
                                    <div className="col-span-3">
                                        <button className="bg-menu-button rounded-md text-white hover:bg-menu-button-hover
                                                    cursor-pointer
                                                    max:mr-4 2xl:mr-4 xl:mr-4 lg:mr-1
                                                    max:px-4 2xl:px-4 xl:px-2 lg:px-1
                                                    max:h-8 2xl:h-8 xl:h-8 lg:h-8
                                                    max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-14"
                                            onClick={() => navigate('/request-demo')}
                                        >
                                            {this.props.locale[`${this.props.lang}`].REQUEST_DEMO}
                                        </button>
                                        <button className="border-2 border-content-green rounded-md text-body-content-green
                                                    hover:border-dark-green hover:text-body-header2 cursor-pointer
                                                    max:px-4 2xl:px-4 xl:px-2 lg:px-1
                                                    max:h-8 2xl:h-8 xl:h-8 lg:h-8
                                                    max:text-size-16 2xl:text-size-16 xl:text-size-14 lg:text-size-14"
                                            onClick={() => window.open('https://shop.aappoint.me/')}
                                        >
                                            {this.props.locale[`${this.props.lang}`].SHOP_LOGIN}
                                        </button>
                                    </div>
                                    {this.props.lang === 'EN' ?
                                        <div
                                            className="cursor-pointer border-4 border-border-change-lang
                                            rounded-lg h-8 w-8 text-center font-bold text-menu-color"
                                            onClick={() => this.props.toggleLang()}
                                        >
                                            EN
                                        </div>
                                        :
                                        <div
                                            className="cursor-pointer border-4 border-border-change-lang
                                            rounded-lg h-8 w-8 text-center font-bold text-menu-color"
                                            onClick={() => this.props.toggleLang()}
                                        >
                                            TH
                                        </div>
                                    }
                                </div>
                                <div className="h-4"></div>
                            </div>
                            <Divider />
                        </div>
                        <div className="h-20"></div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={1023}>
                    <nav className="
                            flex flex-wrap fixed w-full z-50 items-center justify-between bg-white
                            p-4 md:p-2 sm:p-4
                            h-16 md:h-24 sm:h-20
                    ">
                        <div className="container-mobile mx-auto">
                            <div className="grid grid-cols-4 gap-2 items-center">
                                <img
                                    alt="aappointLogoMobile"
                                    className="cursor-pointer col-span-1 w-90p"
                                    onClick={() => navigate('/')}
                                    src={aappointLogo}
                                />
                                <div className="col-span-2"></div>
                                {this.state.expandMenu === false ? (
                                    < img
                                        alt="menu"
                                        className="cursor-pointer ml-auto
                                            w-20p md:w-15p sm:w-20p"
                                        onClick={() => this.toggleMenu()}
                                        src={menu}
                                    />
                                ) : (
                                        < img
                                            alt="iconClose"
                                            className="cursor-pointer ml-auto
                                            w-20p md:w-15p sm:w-20p"
                                            onClick={() => this.toggleMenu()}
                                            src={iconClose}
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </nav>
                    {/* menu detail section */}
                    {this.state.expandMenu === true ? (
                        <div
                            lang={`${this.props.lang}`}
                            className="
                            fixed w-full z-40 bg-white
                            text-size-15 md:text-size-18 sm:text-size-18
                        ">
                            <div className="h-16 md:h-24 sm:h-20"></div>
                            <div className="container-mobile mx-auto text-center p-4">
                                <div
                                    onClick={() => navigate('/')}
                                    className=" text-menu-color font-medium text-left"
                                >
                                    {this.props.locale[`${this.props.lang}`].FEATURE}
                                </div>
                                <div className="h-2"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className=" text-menu-color font-medium text-left"
                                >
                                    {this.props.locale[`${this.props.lang}`].PRICING}
                                </div>
                                <div className="h-2"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className=" text-menu-color font-medium text-left"
                                >
                                    {this.props.locale[`${this.props.lang}`].SOLUTION}
                                </div>
                                <div className="h-2"></div>
                                <div
                                    onClick={() => navigate('/')}
                                    className=" text-menu-color font-medium text-left"
                                >
                                    {this.props.locale[`${this.props.lang}`].ABOUT_US}
                                </div>
                                <div className="h-2"></div>
                                <div
                                    onClick={async () => {
                                        let templang = await this.props.toggleLang()
                                        this.inverseLang(templang)
                                    }}
                                    className=" text-menu-color font-medium text-left"
                                    lang={`${this.state.inverseLang}`}>
                                    {this.props.locale[`${this.props.lang}`].LANG}
                                </div>
                                <div className="h-2"></div>
                                <Divider />
                                <div className="h-4"></div>
                                <button className="bg-menu-button rounded-md text-white w-40 h-8"
                                    onClick={() => navigate('/')}
                                >
                                    {this.props.locale[`${this.props.lang}`].REQUEST_DEMO}
                                </button>
                                <div className="h-4"></div>
                                <button className="border-2 border-content-green rounded-md text-body-content-green
                                                    hover:border-dark-green hover:text-body-header2 cursor-pointer w-40 h-8"
                                    onClick={() => window.open('https://shop.aappoint.me/')}
                                >
                                    {this.props.locale[`${this.props.lang}`].SHOP_LOGIN}
                                </button>
                            </div>
                            <Divider />
                        </div>
                    ) : null}
                    {/* menu detail section */}
                    {this.state.expandMenu === true ? null : (
                        <div className=" fixed w-full mt-16 md:mt-24 sm:mt-20">
                            <Divider
                                orientation="horizontal"
                                variant="fullWidth"
                            />
                        </div>
                    )}
                </MediaQuery>
            </div>
        )
    }
}

export default Header